@import '../../../../styles/colors';
.hintText {
  font-weight: 700;
  font-size: 14px;
  margin-top: 30px;
  color: $orange;
  text-align: center;
}
.container {
  padding: 0 53px;
  @media screen and (max-width: 576px) {
    padding: 0;
    min-height: 100vh;
  }
}
.wrapImg {
  max-height: 230px;
  width: 376px;
  @media screen and (max-width: 576px) {
    width: 95%;
    max-height: 200px;
    width: 300px;
    padding: 0 2vw;
  }
}