$blue: #3fc5ea;
$orange: #ff774d;
$grey: #f6f9fc;
$darkGrey: #8aa3b9;
$darkGrey2: #3d5e7b;
$dark: #1e0705;
$lightBlue: #8de0f6;
$white: #ffffff;
$black1: #000e42;
$green: #2EA72E;
