@import './colors';

.wrapButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 7%;
  width: 100%;
  :nth-child(2) {
    margin-left: 22px;
  }
  @media screen and (max-width: 576px) {
    :nth-child(2) {
      margin-left: 3vw;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    max-width: 100%;
    bottom: 0;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.08);
    left: 0;
    background-color: white;
    padding-bottom: 7%;
  }
}

.boldText {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin: 0;
  padding: 8vh 0 4vh;
  font-family: 'Mulish';
  font-style: normal;
  text-align: center;
  @media screen and (max-width: 576px) {
    padding: 3vh 6vw 4vh;
  }
}

.tipContainer {
  background-color: $white;
  opacity: 1;
  border-radius: 0;
  margin-left: 10px;
  opacity: 1 !important;
  filter: drop-shadow(0 0 0.4rem rgb(212, 211, 211));
}

.wrapCheckbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
  .checkbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    p {
      margin: 0;
      padding: 0;
    }
  }
}
