.wrap {
  padding: 0 56px;
  @media screen and (max-width: 576px) {
    padding: 0;
  }
}
.enterManualContainer {
  display: flex;
  justify-content: center;
}
