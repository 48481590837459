@import '../../../../styles/colors';

.wrap {
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px 53px 0;
  margin: 0;
  border-radius: 6px;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 576px) {
      margin-top: 47px
    }
  }
  .logoImg {
    width: 200px;
    height: 40px;
  }
  @media screen and (max-width: 576px) {
    padding:0;
    height: 100vh;
    justify-content: space-between;
    img {
      width: 39px; 
      height: 40px;
    }
  }
}
.importanttext {
  color: $dark;
  max-width: 392px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin: 0 0 35px 0;
  font-style: normal;
  @media screen and (max-width: 576px) {
    max-width:289px;
  }
}
.widthText {
  padding: 56px 0 16px 0 !important;
  @media screen and (max-width: 576px) {
    max-width:160px !important;
  }
}