.wrap {
  padding: 0 53px;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 576px) {
    padding:0;
  }
}
.marginCheckbox {
  @media screen and (max-width: 576px) {
    margin: 3vh 0;
  }
}
.notesText {
  max-width: 300px;
  text-align: center;
  @media screen and (max-width: 576px) {
    max-width: 200px;
  }
}
.tipContainerMargin {
  margin-bottom: 5px;
}