@import '../../../../styles/colors';

.wrap {
  padding: 0 53px;
  min-height: 450px;
  .text {
    padding-left:4vw;
    padding-right:4vw;
  }
  .errorText {
    font-size: 16px;
    color: $orange;
    line-height: 19px;
    text-align: center;
  }
  .row {
    display: flex;
    flex-direction: row;
  }
  .tipContainer {
    background-color: $white;
    opacity: 1;
    border-radius: 0;
    margin-left: 10px;
    opacity: 1 !important;
    filter: drop-shadow(0 0 0.4rem rgb(212, 211, 211));
  }
  .suggestedTimeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .suggesteTimeText {
    font-size: 24px;
    font-weight: 700;
    color: $orange;
    text-align: center;
    font-family: 'Mulish';
    margin-right: 10px;
  }
  .tipContentText {
    font-weight: 400;
    font-family: 'Mulish';
    font-size: 12px;
    color: $darkGrey2;
  }
  .tipContentTimeText {
    font-weight: 400;
    font-family: 'Mulish';
    font-size: 12px;
    color: $orange;
  }
  .tipContentTextBold {
    font-weight: 700;
    font-family: 'Mulish';
    font-size: 12px;
    color: $darkGrey2;
  }
  .timePicker {
    position: absolute;
    right: 40px;
  }
  @media screen and (max-width: 576px) {
    padding: 0;
    .suggesteTimeText {
      font-size: 18px;
    }
  }
}
