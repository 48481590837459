@import '../../styles/colors';

.wrapper {
  background-color: $white;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  .wrap {
    background-color: $white;
    border-radius: 6px;
    width: 766px;
    @media screen and (max-width: 576px) {
      max-width: 100%;
      border-radius: 0;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      width: 600px;
    }
  }
  .wrapContent {
    padding-bottom: 49px;
    @media screen and (max-width: 576px) {
      padding-bottom: 0;
      height: 100%;
    }
  }
  @media screen and (max-width: 576px) {
    padding-top: 0;
    overflow: visible;
    background-color: white;
  }
  .cancelContainer {
    display: flex;
    justify-content: flex-end;
    padding: 10px 10px 0px 0px;
  }
  .wrapStepper {
    width: 100%;
  }
}
