@import '../../../../styles/colors';

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 53px;
  @media screen and (max-width: 576px) {
    padding: 0;
    .container {
      padding: 0 4vw;
    }
  }
}
.optionTitle {
  text-align: center;
  font-weight: 700;
  color: $darkGrey2;
}
.inactiveImage {
  border-radius: 6px;
  filter: drop-shadow(0 0 0.4rem rgb(212, 211, 211));
  @media screen and (max-width: 576px) {
    width: 120px;
    height: 120px;
  }
}
.activeImage {
  border: solid;
  border-width: 1px;
  border-radius: 6px;
  border-color: $blue;
  box-shadow: 2px 8px 16px rgba(63, 197, 234, 0.2);
  @media screen and (max-width: 576px) {
    width: 120px;
    height: 120px;
  }
}
.spacer {
  height: 60px;
  width: 200px;
}
.hintText {
  color: $orange;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  font-family: 'Mulish';
  @media screen and (max-width: 576px) {
    text-align: left;
    max-width: 150px;
    margin-left: 20%;
  }
}
.timeInputContainer {
  padding-top: 35px;
}
.checkboxContainer {
  display: flex;
  flex-direction: row;
}
.willCallHint1 {
  font-family: 'Mulish';
  font-size: 14px;
  color: $darkGrey2;
}
.willCallHint2 {
  font-family: 'Mulish';
  font-size: 14px;
  color: $orange;
}
.wrapRoundTrip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrapPickupTime {
  @media screen and (max-width: 576px) {
    margin: 10px 0 90px;
  }
}
