.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 53px;
  @media screen and (max-width: 576px) {
    padding: 0;
    .container {
    padding: 0 4vw;
    }
  }
}
