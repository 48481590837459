@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src: url('./fonts/Roboto-Light.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url('./fonts/Roboto-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: url('./fonts/Roboto-Medium.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Mulish';
  src: url('./fonts/Mulish-Bold.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Mulish';
  font-weight: 400;
  src: url('./fonts/Mulish-Regular.ttf');
  font-display: swap;
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-family: 'Mulish';
  scroll-behavior: smooth;
  // overflow-y: hidden;

  @media screen and (max-width: 1024px) {
    overflow-x: hidden;
    max-width: 100vw;
  }
  form {
    width: 100%;
    @media screen and (max-width: 576px) {
    min-height: 84vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    }
  }
}
