.wrap {
  padding: 0 53px;
  .wrapImg {
    margin-top: 47px;
  }
  .wrapLogoText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 576px) {
    padding: 0;
    form {
      min-height: 100vh !important;
    }
    .wrapSelect {
      padding: 0 4vw;
    }
    .wrapImg {
      width: 45px;
      height: 45px;
    }
  }
}
