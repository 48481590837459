.wrap {
  padding: 0 56px;
  @media screen and (max-width: 576px) {
    padding: 0;
  }
}
.wrapInput {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
