@import '../../../../styles/colors';

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 53px;
  .text {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    margin: 0;
    padding: 56px 0 45px 0;
    font-family: 'Mulish';
    font-style: normal;
  }
  @media screen and (max-width: 576px) {
    padding: 0;
    .wrapConatiner {
      padding: 0 4vw;
    }
  }
}
.row {
  display: flex;
  flex-direction: row;
}
.tipContainer {
  background-color: $white;
  opacity: 1;
  border-radius: 0;
  margin-left: 10px;
  opacity: 1 !important;
  filter: drop-shadow(0 0 0.4rem rgb(212, 211, 211));
}
.wrapPhoneCheckbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
  justify-content: space-between;
  .checkbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    p {
      margin: 0;
      padding: 0;
    }
  }
}
.headerCheckbox {
  margin: 20px 10px 20px 0px;
  padding: 0;
  @media screen and (max-width: 576px) {
    margin: 38px 0 15px;
  }
}
.wrapCheckbox {
  margin-left: -0.5vw;
  @media screen and (max-width: 576px) {
    margin-left: -3vw;
  }
}
